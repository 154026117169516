html {
    height: 100%;
}

body {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    background: white;
    font-family: 'Press Start 2P', monospace;

    // height: fill-available;
    // min-height: 100vh;

    // text-rendering: optimizeSpeed;
}

a {
    overflow: hidden;
    outline: none;
}

a,
a:link,
a:visited,
a:active {
    color: #000;
    text-decoration: none;
}

[hidden] {
    display: none !important;
}
